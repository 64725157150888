<template>
  <div>
    <div v-if="isEmpty" class="text-center px-8">
      <AppIcon
        v-if="display.emptyIcon"
        :name="display.emptyIcon"
        size="40"
        class="opacity-50 mb-2"
      />
      <div v-if="display.emptyDescription" class="mb-4">
        {{ display.emptyDescription }}
      </div>
      <v-btn @click="add" class="text-none" elevation="1">
        {{ display.addText }}
      </v-btn>
    </div>
    <template v-else>
      <div class="grid grid-cols-12 gap-4">
        <div
          class="col-span-12"
          :class="{
            'md:col-span-4': !horizontal,
          }"
        >
          <SectionContentMenu :can-remove="canRemove" />
          <div class="flex justify-end">
            <v-btn @click="add" class="mt-2 text-none" elevation="1">
              {{ display.addText }}
            </v-btn>
          </div>
        </div>
        <div
          class="col-span-12"
          :class="{
            'md:col-span-8': !horizontal,
          }"
        >
          <div class="border rounded-lg overflow-hidden">
            <v-window v-model="controls.tab">
              <v-window-item
                v-for="(item, i) in displayItems"
                :key="item.key"
                :value="item.key"
              >
                <slot
                  name="section-content"
                  v-bind="{
                    key: item.key,
                    item: item.item,
                    canRemove: canRemove,
                    model: makeProxy(i),
                    valid: makeValidProxy(item.item),
                    remove: () => remove(item.item),
                  }"
                />
              </v-window-item>
            </v-window>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useSectionContentStore } from "../private/section-content/store";
import SectionContentMenu from "../private/section-content/components/SectionContentMenu.vue";
import { provideSectionContentStoreId } from "../private/section-content/lib";

export default defineComponent({
  name: "SectionsContent",
  props: {
    id: { type: String, required: true },
    horizontal: { type: Boolean, default: false },
    min: { type: [String, Number] },
  },
  components: { SectionContentMenu },
  setup(props) {
    provideSectionContentStoreId(props.id);
    const store = useSectionContentStore<any>(props.id);
    const { display, controls, isEmpty, items, displayItems } =
      storeToRefs(store);

    const canRemove = computed(() => {
      if (props.min) {
        return items.value.length > Number(props.min);
      }
      return true;
    });

    const makeProxy = (index: number) => {
      return computed({
        set(value: any) {
          items.value[index] = value;
        },
        get() {
          return items.value[index];
        },
      });
    };

    const makeValidProxy = (item: unknown) => {
      return computed({
        set(value: boolean) {
          store.setValid(item, value);
        },
        get() {
          return store.getValid(item);
        },
      });
    };

    return {
      display,
      controls,
      isEmpty,
      displayItems,
      canRemove,
      makeProxy,
      makeValidProxy,
      add: store.add,
      remove: store.remove,
    };
  },
});
</script>
