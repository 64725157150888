import { useSectionContentStore } from "./store";

export const provideSectionContentStoreId = (id: string) => {
  provide("__section-content__", id);
};

export const injectSectionContentStore = () => {
  const id = inject("__section-content__") as string;
  const store = useSectionContentStore<any>(id);

  return {
    id,
    add: store.add,
    remove: store.remove,
    setValid: store.setValid,
    getValid: store.getValid,
    set: store.set,
    ...storeToRefs(store),
  };
};
