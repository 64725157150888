import { useSectionContentStore } from "../private/section-content/store";
import type { SectionContentOptions } from "../private/section-content/types";

export const useSectionContent = <T extends any>(
  options: SectionContentOptions<T>
) => {
  const store = useSectionContentStore<T>(options.id);

  store.display.emptyIcon = options.emptyIcon;
  store.display.emptyDescription = options.emptyDescription;
  store.display.addText = options.addText || "Add";

  store.controls.tab = 0;
  store.controls.identify = !!options.identify;
  store.controls.sortable = !!options.sortable;
  store.controls.itemTitle = options.itemTitle || "name";
  store.controls.itemValue = options.itemValue || "id";
  store.controls.sortBy = options.sortBy || "position";

  store.setItemsAccess(options.items);
};
